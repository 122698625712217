<template>
    <Tour name="news" :steps="steps" :options="options" :callbacks="callbacks"/>
</template>

<script>
import Tour from "@/components/tours/Tour.vue"

export default {
    props: {
        callbacks: {type: Object, required: false},
        options: {type: Object, required: false}
    },
    components: {
        Tour
    },
    computed: {
        steps: function () {
            return  [{
                target: '#tour-news',
                header: {
                    title: this.$t('tours.news.title')
                },
                content: this.$t('tours.news.content')
            }]
        }
    }
}
</script>

<style>

</style>