
<router-link
    :to="to"
    custom v-slot="{ navigate, isActive, isExactActive }"
>
    <button
        @click="navigate"
        @keypress.enter="navigate"
        :class="{'router-link-active': (isActive || active) && !deactive, 'router-link-exact-active': isExactActive && !deactive}"
        role="link"
    >
        <slot></slot>
    </button>
</router-link>
