
<AlertBubble
    :show="!busy && company == null && !isAlertDismissed"
    @dismissed="dismissAlert"
>
    {{ $t("noCompanyAlert.text") }}

    <template #button>
        <button class="btn btn-light" @click="$router.push({'name': 'settings'})">
            {{ $t("noCompanyAlert.btn") }}
        </button>
    </template>
</AlertBubble>
