
<v-tour
    :name="name"
    :steps="steps"
    :callbacks="callbacks"

    :options="tourOptions"
>
    <template slot-scope="tour">
        <transition name="fade">
            <v-step
                v-if="tour.steps[tour.currentStep]"
                :key="tour.currentStep"
                :step="tour.steps[tour.currentStep]"
                :previous-step="tour.previousStep"
                :next-step="tour.nextStep"
                :stop="tour.stop"
                :skip="tour.skip"
                :is-first="tour.isFirst"
                :is-last="tour.isLast"
                :labels="tour.labels"
            >
                <div slot="content" class="content">
                    <slot name="content"><div v-html="tour.steps[tour.currentStep].content"></div></slot>
                </div>

                <div slot="actions" class="actions">
                    <slot name="actions" :tour="tour">
                        <b-row>
                            <b-col v-if="!tour.isFirst">
                        <button @click="tour.previousStep" class="v-step__button">{{tour.labels.buttonPrevious}}</button>
                            </b-col><b-col v-if="!tour.isLast">
                        <button @click="tour.nextStep" class="v-step__button">{{tour.labels.buttonNext}}</button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                        <button @click="tour.skip" class="v-step__button" v-if="!tour.isLast">{{tour.labels.buttonSkip}}</button>
                        <button @click="tour.finish" class="v-step__button" v-else>{{tour.labels.buttonStop}}</button>
                            </b-col>
                        </b-row>
                    </slot>
                </div>
            </v-step>
        </transition>
    </template>
</v-tour>
