
<div>
    <header>
        <div class="container-fluid">
            <nav class="grid-row nav">
                <div class="grid-col text-left">
                    <b-navbar>
                        <b-navbar-brand :to="{ name: 'home' }" class="logo">
                            <icon icon="datlab" />
                            <div>{{ $t('zindex') | upper }}</div>
                        </b-navbar-brand>

                        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                        <b-collapse id="nav-collapse" is-nav class="justify-content-end">
                            <slot name="nav">
                                <b-navbar-nav></b-navbar-nav>
                                <b-navbar-nav class="ml-auto">
                                    <b-nav-item
                                        v-for="(n, k) in nav"
                                        :key="`nav-${k}`"
                                        exact
                                        exact-active-class="active"
                                        active-class="active"
                                        v-bind="n"
                                    >
                                        {{ $t(`nav.${k}`) | upper }}
                                    </b-nav-item>
                                </b-navbar-nav>
                            </slot>
                        </b-collapse>
                    </b-navbar>
                </div>
            </nav>

            <div class="grid-row no-gutters site-description">
                <div class="grid-col text-center"></div>
            </div>
        </div>
    </header>

    <main>
        <div class="row justify-content-center">
            <div class="col-11 col-md-11 col-lg-11 col-xl-8 col-xxl-8 col-xxxl-7 col-xxxxl-5">
                <slot v-if="blankMainSection"></slot>
                <grid-row v-else>
                    <slot></slot>
                </grid-row>
            </div>
        </div>
    </main>

    <footer id="claim" class="text-left" v-if="!noFooter">
        <div class="line-logo">
            <icon icon="institut" />
        </div>

        <grid-row>
            <div class="row">
                <div class="col">
                    <div class="footer-icon">
                        <icon icon="big-d" /><span>I</span>
                    </div>
                </div>
            </div>
            <div class="row justify-content-between">
                <div class="col-lg-5 col-xl-4 claim">
                    <p>{{ $t('footerZindex.claim.0') }}</p>
                    <p>{{ $t('footerZindex.claim.1') }}</p>
                </div>

                <div class="col-auto contact">
                    <h4>{{ $t('footerZindex.contact') | upper }}</h4>
                    <p class="address">
                        {{ $t('footerZindex.address.name') }}<br />
                        {{ $t('footerZindex.address.street') }}<br />
                        {{ $t('footerZindex.address.city') }}<br />
                        {{ $t('footerZindex.address.country') }}<br />
                    </p>
                </div>

                <div class="col-auto links">
                    <h4>{{ $t('footer.products') | upper }}</h4>
                    <a href="https://zindex.cz/" target="_blank">Zindex.cz</a>
                    <a href="https://politickefinance.cz/" target="_blank">Politickefinance.cz</a>
                    <a href="https://www.rekonstrukcestatu.cz/" target="_blank">Rekonstrukce státu</a>
                </div>

                <div class="col-auto others">
                    <h4>{{ $t('footer.others') | upper }}</h4>
                    <a href="https://datlab.eu/blog" target="_blank">Blog</a>
                    <a href="https://www.facebook.com/datlab.cz" target="_blank">Facebook</a>
                    <a href="https://datlab.eu/" target="_blank">Datlab</a>
                </div>
            </div>
        </grid-row>
    </footer>

    <footer id="mandatory-info" class="text-left" v-if="!noFooter">
        <grid-row>
            <div class="row justify-content-between">
                <div class="col">{{ $t('footerZindex.copyright') }}</div>
                <div class="col col-auto">{{ $t('emailZindex') }}</div>
            </div>
        </grid-row>
    </footer>
</div>
