<template>
    <component :is="icon" class="icon" :class="[liftUp? 'lift-up': '']" v-bind="$attrs" />
    <!-- <img :src="`${baseUrl}images/${file}`" role="img" alt="icon" /> -->
</template>

<script>

export default {
    name: "icon",
    inheritAttrs: false,
    props: {
        icon: {type: String, required: true},
        liftUp: {type: Boolean, required: false, default: false}
    },
    components: {
        'datlab':       () => import('@/components/icons/Datlab.vue'),
        'dbl-arrow':    () => import('@/components/icons/DoubleArrow.vue'),
        'big-d':        () => import('@/components/icons/BigD.vue'),
        'alarm':        () => import('@/components/icons/Alarm.vue'),
        'apps':         () => import('@/components/icons/Apps.vue'),
        'diamond':      () => import('@/components/icons/Diamond.vue'),
        'focus':        () => import('@/components/icons/Focus.vue'),
        'list':         () => import('@/components/icons/ListSearch.vue'),
        'speaker':      () => import('@/components/icons/Speakerphone.vue'),
        'tendry':       () => import('@/components/icons/Tendry.vue'),
        'rocket':       () => import('@/components/icons/Rocket.vue'),
        'check':        () => import('@/components/icons/Check.vue'),
        'search':       () => import('@/components/icons/Search.vue'),
        'arrow':        () => import('@/components/icons/Arrow.vue'),
        'user':         () => import('@/components/icons/User.vue'),
        'news':         () => import('@/components/icons/News.vue'),
        'notification': () => import('@/components/icons/Notification.vue'),
        'point':        () => import('@/components/icons/Point.vue'),
        'dots':         () => import('@/components/icons/Dots.vue'),
        'tallymarks':   () => import('@/components/icons/Tallymarks.vue'),
        'heart':        () => import('@/components/icons/Heart.vue'),
        'activity':     () => import('@/components/icons/Activity.vue'),
        'pie':          () => import('@/components/icons/Pie.vue'),
        'arrow-narrow': () => import('@/components/icons/ArrowNarrow.vue'),
        'thumb':        () => import('@/components/icons/Thumb.vue'),
        'star':         () => import('@/components/icons/Star.vue'),
        'help':         () => import('@/components/icons/Help.vue'),
        'menu-toggler': () => import('@/components/icons/MenuToggler.vue'),
        'x':            () => import('@/components/icons/X.vue'),
        'eye':          () => import('@/components/icons/Eye.vue'),
        'eye-off':      () => import('@/components/icons/EyeOff.vue'),
        'eye-check':    () => import('@/components/icons/EyeCheck.vue'),
        'triangle':     () => import('@/components/icons/Triangle.vue'),
        'tool':         () => import('@/components/icons/Tool.vue'),
        'plus':         () => import('@/components/icons/Plus.vue'),
        'trash':        () => import('@/components/icons/Trash.vue'),
        'bookmark':     () => import('@/components/icons/Bookmark.vue'),
        'license':      () => import('@/components/icons/License.vue'),
        'institut':     () => import('@/components/icons/Institut.vue'),
        'cancelled':    () => import('@/components/icons/Cancelled.vue'),
        'lock':         () => import('@/components/icons/Lock.vue'),
        'download':     () => import('@/components/icons/Download.vue'),
        'file-zip':     () => import('@/components/icons/FileZip.vue'),
        'file-cer':     () => import('@/components/icons/FileCer.vue'),
        'file-other':   () => import('@/components/icons/FileOther.vue'),
        'ext-link':     () => import('@/components/icons/ExternalLink.vue'),
        'calendar':     () => import('@/components/icons/Calendar.vue'),
        'plus-sqr':     () => import('@/components/icons/SquarePlus.vue'),
        'money':        () => import('@/components/icons/Money.vue'),
        'edit':         () => import('@/components/icons/Edit.vue'),
        'sign':         () => import('@/components/icons/Sign.vue'),
        'award':        () => import('@/components/icons/Award.vue'),
        'atom':         () => import('@/components/icons/Atom.vue'),
        'dashboard':    () => import('@/components/icons/Dashboard.vue'),
        'logout':       () => import('@/components/icons/Logout.vue'),
        'settings':     () => import('@/components/icons/Settings.vue'),
        'hand-rock':    () => import('@/components/icons/HandRock.vue'),
        'hand-lf':      () => import('@/components/icons/HandLittleFinger.vue'),
        'comet':        () => import('@/components/icons/Comet.vue'),
        'nervous':      () => import('@/components/icons/Nervous.vue'),
        'hand':         () => import('@/components/icons/Hand.vue'),
        'apple':        () => import('@/components/icons/Apple.vue'),
        'arrows':       () => import('@/components/icons/Arrows.vue'),
        'business-plan': () => import('@/components/icons/BusinessPlan.vue'),
        'check-circle': () => import('@/components/icons/CheckCircle.vue'),
        'flame':        () => import('@/components/icons/Flame.vue'),
        'flame-red':        () => import('@/components/icons/FlameRed.vue'),
        'bulb':         () => import('@/components/icons/Bulb.vue'),
        'candy':        () => import('@/components/icons/Candy.vue')
    }
}
</script>

<style scoped lang="scss">
.fill {
    fill: currentColor;
}

.lift-up {
    margin-bottom: 0.25em;
}
</style>