
<grid-row class="auth-layout">
    <b-row class="justify-content-center">
        <b-col class="logo">
             <router-link :to="{name: 'home'}">
                <icon icon="datlab" />
                <div>{{ $t('tendry') | upper }}</div>
            </router-link>
        </b-col>
    </b-row>

    <slot name="title">
        <h1>{{ title }}</h1>
    </slot>

    <b-row class="justify-content-center">
        <b-col class="content">
            <div v-for="(e, i) in errorsArr" :key="`err-${i}`" class="error">{{ e }}</div>

            <slot></slot>
        </b-col>
    </b-row>
</grid-row>
