var config = {
    apiBaseUrl: "http://localhost:8000/api/",
    landingPageUrl: "http://localhost:8000",
    vueAppUrl: "http://localhost:8001",
    aresApiUrl: "https://ares.datlab.eu/",
    bpUrl: "https://bpapi.datlab.eu/",
    rzUrl: "https://rzapi.datlab.eu/",
    tag: "#",
    maxStars: 5,
    plausibleDomain: "localhost",
    plausibleTrackingApi: "https://plausible.datlab.eu"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "production") {
    config.apiBaseUrl = "https://tendry.cz/api/"
    config.landingPageUrl = "https://tendry.cz/"
    config.vueAppUrl = "https://tendry.cz/app"
    config.plausibleDomain = "tendry.cz"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "borg_production") {
    config.apiBaseUrl = "https:///tendry.cz/api/"
    config.landingPageUrl = "https://tendry.cz/"
    config.vueAppUrl = "https://tendry.cz/app"
    config.plausibleDomain = "tendry.cz"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "staging") {
    config.apiBaseUrl = "https://tendry-staging.datlab.eu/api/"
    config.landingPageUrl = "https://tendry-staging.datlab.eu/"
    config.vueAppUrl = "https://tendry-staging.datlab.eu/app"
    config.plausibleDomain = "staging.tendry.cz"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "evaluation") {
    config.gtmkey = "GTM-MQPDDSN"
    config.apiBaseUrl = "https://dodavatel.zindex.cz/api/"
    config.landingPageUrl = "https://dodavatel.zindex.cz/"
    config.vueAppUrl = "https://tendry.cz/app"
    config.plausibleDomain = "tendry.cz"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "dev01_kuba") {
    config.apiBaseUrl = "http://localhost:22101/api/"
    config.landingPageUrl = "http://localhost:22101/"
}

if (import.meta.env.VITE_VUE_APP_SERVER == "dev_matej") {
    config.apiBaseUrl = "http://localhost:35401/api/"
    config.landingPageUrl = "http://localhost:35401/"
    config.vueAppUrl = "http://localhost:35402"
}

export const CONFIG = config
