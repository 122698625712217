<template>
    <b-modal
        content-class="text-center"
        hide-footer
        hide-header
        centered

        :id="id"
        v-on="$listeners"
    >
        <template #default="{cancel, ok, close}">
            <b-row>
                <b-col class="text-right">
                    <button class="btn-close" @click="close">
                        <icon icon="x"/>
                    </button>
                </b-col>
            </b-row>

            <b-row class="header">
                <b-col>{{ title }}</b-col>
            </b-row>
            <b-row class="text">
                <b-col>{{ text }}</b-col>
            </b-row>
            <b-row class="buttons">
                <b-col>
                    <button v-if="cancelBtnLabel" class="btn btn-light" @click="cancel">
                        {{ cancelBtnLabel }}
                    </button>
                    <button v-if="okBtnLabel" class="btn" :class="`btn-${okBtnVariant}`" @click="ok">
                        {{ okBtnLabel }}
                    </button>
                </b-col>
            </b-row>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        id: {type: String, required: true},
        title: {type: String},
        text: {type: String},
        okBtnLabel: {type: String},
        cancelBtnLabel: {type: String},
        okBtnVariant: {type: String, default: "danger"}
    }

}
</script>

<style>

</style>