<template>
    <div>
        <b-container fluid id="feeds-layout">
            <b-row>
                <b-col id="side-menu" cols="auto" :class="{expanded: sideMenuExpanded}">
                    <div class="side-menu-inner-sticky d-flex flex-column">
                        <div class="menu-upper-section flex-shrink-0">
                            <div class="logo">
                                <div>
                                    <icon icon="datlab"/>
                                    <div>{{ $t('tendry') | upper }}</div>
                                </div>
                                <button class="close-button" @click="toggleSideMenu(false)">
                                    <icon icon="x"/>
                                </button>
                            </div>

                            <div class="user" v-if="user">
                                <div class="menu-item pr-0">
                                    <div class="d-inline-block text-truncate" id="email">
                                        <icon icon="user"/>{{ user.email }}
                                    </div>
                                    <b-tooltip target="email" triggers="hover" boundary-padding="2">
                                        {{ user.email }}
                                    </b-tooltip>
                                    <button @click="() => toggleUserMenu()" class="btn menu-toggler">
                                        <icon v-if="userMenuExpanded" icon="triangle" down/>
                                        <icon v-else icon="triangle" up/>
                                    </button>
                                </div>
                                <ul class="user-menu" v-if="userMenuExpanded">
                                    <li>
                                        <router-button :to="{ name: 'settings' }" class="menu-item">
                                            <icon icon="settings"/>
                                            {{ $t("settings.nav") }}
                                        </router-button>
                                    </li>
                                    <li>
                                        <router-button :to="{ name: 'subscription' }" class="menu-item">
                                            <icon icon="candy"/>
                                            {{ $t("subscription.nav") }}
                                        </router-button>
                                    </li>
                                    <li>
                                        <router-button :to="{ name: 'logout' }" class="menu-item">
                                            <icon icon="logout"/>
                                            {{ $t("auth.logout.button") }}
                                        </router-button>
                                    </li>
                                </ul>
                            </div>

                            <div class="menu-item trial" v-if="user.subscription_type == 'SubscriptionType.TRIAL'">
                                <p>
                                    {{ $t("subscription.trial.info", { until: $moment(user.subscribed_until).format("D.M.") }) }}
                                </p>

                                <router-button :to="{name: 'subscription'}">
                                    {{ $t("subscription.trial.btn") }}
                                </router-button>
                            </div>

                            <router-button class="menu-item" :to="{name: 'search'}">
                                <icon icon="search"/>
                                {{ $t('feeds.menu.search') }}
                            </router-button>

                            <router-button class="menu-item menu-item-count" :to="{name: 'news'}">
                                <icon icon="news"/>
                                <span>{{ $t('feeds.menu.news') }}</span>
                                <span class="new-count">
                                    <loader v-if="newsCountBusy" small />
                                    <template v-else>{{ newsCount }}</template>
                                </span>
                            </router-button>

                            <span v-if="favorites && favorites.length">
                                <h5>
                                    {{ $t("feeds.menu.favourites") | upper }}
                                </h5>
                                <loader v-if="feedsBusy" centered small/>
                                <template v-else-if="favorites && favorites.length">
                                    <router-button
                                        v-for="f in favorites" :key="`favorite-${f.id}`"
                                        class="menu-item menu-item-feed"
                                        :to="{name: 'feed-detail', params: {id: f.id}}"
                                    >
                                        <icon icon="point"/>
                                        <span>{{ f.title }}</span>
                                    </router-button>
                                </template>
                                <div v-else class="empty-menu-item">
                                    {{ $t("feeds.menu.empty") }}
                                </div>
                            </span>

                            <h5>
                                <div>{{ $t('feeds.menu.smartFeed') | upper }}</div>
                            </h5>
                            <loader v-if="feedsBusy" centered small />
                            <template v-else-if="smartFeed">
                                <router-button
                                    class="menu-item menu-item-count"
                                    id="tour-smart"
                                    :to="{name: 'feed-detail', params: {id: smartFeed.id}}"
                                    :active="$route.name == 'feed-complete'"
                                    :deactive="$route.name == 'dashboard'"
                                >
                                    <icon icon="atom"/>
                                    <span>{{ $t("feeds.menu.smartFeedAlt") }}</span>
                                    <span class="new-count">
                                        <loader v-if="feedsStatsBusy" small />
                                        <template v-else-if="smartFeed.id in feedsStats">{{ feedsStats[smartFeed.id].new_count }}</template>
                                        <template v-else>0</template>
                                    </span>
                                </router-button>

                                <router-button class="menu-item" :to="{name: 'dashboard', params: {id: smartFeed.id}}">
                                    <icon icon="dashboard"/>
                                    <span>{{ $t("feeds.menu.dashboard") }}</span>
                                </router-button>
                            </template>

                            <h5>
                                <div>{{ $t('feeds.menu.feeds') | upper }}</div>
                                <button
                                    id="tour-feed"
                                    @click="$router.push({name: 'feed-create'})"
                                    class="btn menu-toggler"
                                    :disabled="
                                        $router.currentRoute.name == 'feed-create'
                                    "
                                >
                                    <icon icon="plus" class="btn-create-feed" />
                                </button>
                            </h5>
                            <loader v-if="feedsBusy" centered small />
                            <template v-else-if="feedsWithoutSmart && feedsWithoutSmart.length">
                                <router-button
                                    v-for="(f, i) in feedsWithoutSmart"
                                    :key="`feed-${f.id}`"
                                    :id="`feed-${f.id}`"
                                    class="menu-item menu-item-feed menu-item-count"
                                    :to="{name: 'feed-detail', params: {id: f.id}}"
                                >
                                    <icon icon="point"/>
                                    <span>{{ f.title }}</span>
                                    <span class="new-count" :id="i == 0 ? 'tour-news' : ''">
                                        <loader v-if="feedsStatsBusy" small />
                                        <template v-else-if="f.id in feedsStats">{{ feedsStats[f.id].new_count }}</template>
                                        <template v-else>0</template>
                                    </span>
                                </router-button>
                            </template>
                            <div v-else class="empty-menu-item">{{ $t("feeds.menu.empty") }}</div>
                        </div>
                        <div class="mt-auto menu-footer-section">
                            <a
                                href="mailto:kontakt@tendry.cz"
                                class="menu-item menu-item-help"
                            >
                                <icon icon="speaker" />
                                <span>{{ $t("feeds.menu.contact") }}</span>
                            </a>

                            <a href="https://tendry.notion.site/Tipy-a-N-vody-03872dbde7924cb8b565f0783b488e07"
                                class="menu-item menu-item-help"
                                target="_blank"
                            >
                                <icon icon="help"/>
                                <span>{{ $t("feeds.menu.help") }}</span>
                            </a>
                        </div>
                    </div>
                </b-col>
                <b-col id="main">
                    <b-row v-if="!noHeader" >
                        <b-col id="top-menu" >
                            <b-row no-gutters align-v="center">
                                <b-col class="col-auto col-md-auto order-1 order-md-1 text-left">
                                    <button @click.stop="sideMenuExpanded = !sideMenuExpanded"
                                            class="btn-icon menu-toggler">
                                        <icon icon="menu-toggler"/>
                                    </button>
                                    <button @click="$router.go(-1)" class="btn-icon history prev">
                                        <icon icon="arrow"/>
                                    </button>
                                    <button @click="$router.go(1)" class="btn-icon history next">
                                        <icon icon="arrow" right/>
                                    </button>
                                </b-col>
                                <b-col class="col-12 col-md order-3 order-md-2 title_row">
                                    <slot name="title"></slot>
                                </b-col>
                                <b-col class="col col-md-auto order-2 order-md-3 text-right">
                                    <slot name="top-menu" :toggleRightPane="toggleRightPane"></slot>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row id="content-wrapper">
                        <b-col id="content">
                            <loader v-show="busy" :label="$t('tender.listLoader')" centered class="content-loader" />
                            <div v-show="!busy">
                                <div id="alerts-container">
                                    <SeedPopulateAlert v-if="seedPopulateAlert" class="alert-bubble-row" />

                                    <NoCompanyAlert v-if="noCompanyAlert" class="alert-bubble-row" />

                                    <slot name="alerts"></slot>
                                </div>

                                <slot :toggleRightPane="toggleRightPane">
                                </slot>
                            </div>
                        </b-col>
                        <b-col cols="4" v-if="rightPaneExpanded" id="right-pane" :class="{expanded: rightPaneExpanded}">
                            <slot name="right-pane" :toggleRightPane="toggleRightPane">
                            </slot>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-container>

        <TourDiscount :callbacks="getTourCallbacks('discount')" />
        <TourSmart :callbacks="getTourCallbacks('smart')" />
        <TourFeed :callbacks="getTourCallbacks('feed')" />
        <TourSettings :callbacks="getTourCallbacks('settings')" />
        <TourNews :callbacks="getTourCallbacks('news')" />
    </div>
</template>

<script>
import TourSmart from "@/components/tours/TourSmart.vue"
import TourFeed from "@/components/tours/TourFeed.vue"
import TourSettings from "@/components/tours/TourSettings.vue"
import TourNews from "@/components/tours/TourNews.vue"
import TourDiscount from "@/components/tours/TourDiscount.vue"
import SeedPopulateAlert from "@/components/SeedPopulateAlert.vue"
import NoCompanyAlert from "@/components/NoCompanyAlert.vue"

export default {
    props: {
        busy: {type: Boolean, default: false},
        noHeader: {type: Boolean, default: false},
        seedPopulateAlert: {type: Boolean, default: false},
        noCompanyAlert: {type: Boolean, default: false}
    },
    components: {
        TourSmart, TourFeed, TourSettings, TourNews, SeedPopulateAlert, NoCompanyAlert, TourDiscount
    },
    data: function () {
        return {
            activeFeed: null,
            rightPaneExpanded: false,
            sideMenuExpanded: false,
            feedsBusy: false,
            feedsStatsBusy: false,
            newsCountBusy: false
        }
    },
    created: function () {
        this.$root.$on("update-feeds-stats", this.updateFeedsStats)

        this.feedsBusy = true
        this.$store.dispatch("loadFeeds")
            .then(() => {
                if (this.feedsStats == null) {
                    this.$root.$emit("update-feeds-stats")
                }
            })
            .catch(e => console.log(e))
            .finally(() => this.feedsBusy = false)

        document.addEventListener('click', function (event) {
            if (this.sideMenuExpanded && !event.target.closest('#side-menu')) {
                this.sideMenuExpanded = false
            }
        }.bind(this))

        this.newsCountBusy = true
        this.$store.dispatch("updateNewsCount")
            .finally(() => this.newsCountBusy = false)
    },
    watch: {
        toursToShow: function () {
            this.handleTours()
        },
        "$route": function () {
            this.handleTours()
        }
    },
    mounted() {
        this.handleTours()
    },
    computed: {
        toursToShow: function() {
            return this.$store.getters.toursToShow
        },
        user: function () {
            return this.$store.getters.user
        },
        feeds: function () {
            return this.$store.getters.feeds
        },
        feedsWithoutSmart: function () {
            if (!this.feeds) {
                return []
            }

            return this.feeds.filter(n => !n.smart)
        },
        smartFeed: function () {
            return this.$store.getters.smartFeed
        },
        favorites: function () {
            if (!this.feeds) {
                return []
            }

            return this.feeds.filter(f => f.favorite)
        },
        feedsStats: function () {
            return this.$store.getters.feedsStats
        },
        newsCount: function () {
            return this.$store.getters.newsCount
        },
        userMenuExpanded: function() {
            return this.$store.getters.userMenuExpanded
        },
        isNonSmartFeedDetail: function () {
            return this.$route.name == "feed-detail" && this.smartFeed && this.$route.params.id != this.smartFeed.id
        },
        tours: function () {
            // keep this order of tours
            return [
                {key: "discount"},
                {key: "smart"},
                {key: "feed"},
                {key: "settings", show: this.isNonSmartFeedDetail},
                {key: "news", show: this.isNonSmartFeedDetail}
            ]
        }
    },
    methods: {
        handleTours: function() {
            // starts the first tour that is possible to show (always only one tour is visible)
            for (const i in this.tours) {
                let tour = this.tours[i],
                    enabled = this.$store.getters.isTourEnabled(tour.key),
                    show = !("show" in tour) || tour.show
                if (!enabled || !show) {
                    continue
                }

                this.startTour(tour)

                break
            }
        },
        startTour: function (tour) {
            // start tour if targets of all steps already exist in DOM, otherwise wait and tray again
            if (this.$tours[tour.key].steps.every(n => document.querySelector(n.target) !== null)) {
                this.$tours[tour.key].start()
            } else {
                setTimeout(() => this.startTour(tour), 100)
            }
        },
        toggleRightPane: function (value) {
            if (typeof value === 'undefined') {
                this.rightPaneExpanded = !this.rightPaneExpanded
            } else {
                this.rightPaneExpanded = value
            }
        },
        toggleSideMenu: function (value) {
            if (typeof value === 'undefined') {
                this.sideMenuExpanded = !this.sideMenuExpanded
            } else {
                this.sideMenuExpanded = value
            }

        },
        toggleUserMenu: function (value) {
            if (typeof value === 'undefined') {
                value = !this.userMenuExpanded
            }

            this.$store.commit("setUserMenuExpanded", value)
        },
        updateFeedsStats: function (force) {
            this.feedsStatsBusy = true
            this.$store.dispatch("loadFeeds", force)
                .then(() => { return this.$store.dispatch("updateFeedsStats") })
                .finally(() => this.feedsStatsBusy = false)
        },
        getTourCallbacks: function(key) {
            let callback = () => this.markTourAsShown(key)

            return {
                onSkip: callback,
                onFinish: callback,
                onStop: callback
            }
        },
        markTourAsShown: function(key) {
            var tours = {...this.toursToShow}
            tours[key] = false
            this.$store.dispatch("updateTours", tours)
        },
        hideTours: function() {
            let nodes = document.querySelectorAll(".v-tour")
            nodes.forEach(n => {
                while (n.firstChild) {
                    n.removeChild(n.firstChild)
                }
            })
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/scss/custom";
@import "src/scss/utils";
@import "bootstrap/scss/mixins";

#feeds-layout {
    background-color: #ffffff;

    .btn-icon {
        background: none;
        border: none;
        padding: 10px;
        border-radius: 8px;
        width: 40px;
        height: 40px;
    }

    #side-menu {
        min-height: 100vh;
        width: 300px;
        background-color: $background-light;
        color: $body-color;
        font-size: 14px;
        font-weight: 600;
        z-index: 3500;
        padding: 0px;

        @include media-breakpoint-down($menu-toggle-breakpoint) {
            &:not(.expanded) {
                display: none;
            }

            height: 100%;
            position: fixed;
            box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.2);
        }

        .side-menu-inner-sticky {
            position: sticky;
            top: 0;
            height: 100vh;
            width: 300px;
            overflow-x: hidden;
            overflow-y: auto;
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 32px;
            padding-bottom: 32px;
        }

        .menu-footer-section {
            padding-top: 32px;
        }

        h5 {
            font-size: 12px;
            opacity: 0.6;
            margin: 32px 0 16px 24px;
            font-weight: 700;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .logo {
            font-weight: 800;
            font-size: 21px;
            margin-bottom: 50px;
            margin-left: 24px;
            display: flex;

            svg {
                font-size: 16px;
            }

            svg + div {
                color: $color-red;
            }

            .close-button {
                border: none;
                background: none;
                padding: none;
                color: $body-color;
                align-self: flex-start;
                margin-left: auto;
                display: none;

                svg {
                    font-size: 24px;
                    stroke-width: 2.5px;
                }

                @include media-breakpoint-down($menu-toggle-breakpoint) {
                    display: block;
                }
            }
        }

        .menu-toggler {
            width: 20px;
            height: 20px;
            padding: 2px;
            border-radius: 5px;
            color: $body-color;
            display: flex;
            align-items: center;
            justify-content: center;

            @include colorize-clickable($background-dark);

            .icon {
                font-size: 8px;
                margin: 0;
                min-width: unset;
                stroke-width: 3.5px;
            }
        }

        .menu-item {
            padding: 12px 8px 12px 24px;
            border-radius: 10px;
            border: none;
            color: inherit;
            font-size: inherit;
            font-weight: inherit;
            text-align: left;
            display: flex;
            align-items: center;
            margin: 0;
            width: 100%;

            svg {
                font-size: 16px;
                margin-right: 10px;
                min-width: 16px;
            }
        }

        .empty-menu-item {
            opacity: 0.6;
            text-align: center;
            font-weight: 400;
            font-style: italic;
        }

        button.menu-item {
            @include colorize-clickable($background-light, $background-dark);
        }

        button.menu-item.router-link-active {
            background-color: $background-dark;
        }

        .user {
            margin-bottom: 20px;

            & > .menu-item {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .user-menu {
                padding: 0px;

                li {
                    list-style: none;

                    .menu-item {
                        padding-left: 48px;
                    }
                }
            }
        }

        .menu-item-feed {
            &:hover svg,
            &.router-link-active svg {
                fill: $body-color;
            }
        }

        .menu-item-count .new-count {
            opacity: 0.6;
            font-size: 10px;
            font-weight: 600;
            margin-left: auto;
            padding-left: 15px;
        }

        .down {
            margin-top: auto;
        }

        .menu-item-help {
            @include colorize-clickable($background-light, $background-dark);
            margin-top: auto;
            color: inherit;
            text-decoration: none;

            span {
                vertical-align: middle;
            }

            svg {
                font-size: 20px;
            }
        }

        .btn-create-feed {
            font-size: 20px !important;
        }

        .loader {
            opacity: 0.6;
        }

        .trial {
            flex-direction: column;
            padding-top: 10px;
            padding-bottom: 30px;

            p {
                color: $color-red;
                margin-bottom: 10px;
            }

            button {
                border: 1px solid $color-red;
                background-color: transparent;
                color: $color-red;
                border-radius: 10px;
                padding: 10px;
                font-weight: 700;
                font-size: 12px;
                width: 100%;

                &:hover {
                    background-color: $color-red;
                    color: white;
                }

            }
        }
    }

    #top-menu {
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $border-light;
        font-size: 18px;
        z-index: 3000;

        @include media-breakpoint-down(sm) {
            position: fixed;
            top: 0px;
            background-color: $background-light;
        }

        .menu-toggler {
            display: none;
            margin-left: -10px;
            padding: 0 !important;
            margin-right: 10px;

            .icon {
                font-size: 24px;
            }

            @include media-breakpoint-down($menu-toggle-breakpoint) {
                display: inline-block;
            }
        }

        button {
            line-height: 1;

            &:focus {
                box-shadow: none;
            }
        }

        button + button {
            margin-left: 10px;
        }

        button.btn-icon {
            background: none;
            border: none;
            padding: 10px;
            border-radius: 8px;
            width: 40px;
            height: 40px;

            &:hover {
                background-color: $background-light;
            }
        }

        button:not(.btn-icon) {
            @include colorize-clickable($body-color, lighten($body-color, 5%));
            color: $body-bg;
            padding: 12px 24px;
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            border: none;

            &.btn-light {
                background-color: $body-bg;
                color: $body-color;

                &:hover {
                    background-color: $background-light;
                }
            }
        }

        button.history {
            color: $body-color;
            display: inline-block;
            width: auto;
            height: auto;
            padding: 0;
            margin: 0;

            svg {
                font-size: 32px;
            }

            &.prev {
                margin-right: 10px;
            }

            &.next {
                margin-right: 24px;
            }

            &:hover {
                background-color: inherit;
            }
        }

        .title_row:not(:empty) {
            @include media-breakpoint-down(sm) {
                padding-top: 10px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    #main {
        display: flex;
        flex-direction: column;
    }

    #content-wrapper {
        // flex fill
        flex: 1 1 auto !important;
        overflow-x: hidden;
        max-width: 100vw;
    }

    #content,
    #right-pane {
        padding-top: 32px;
    }

    #right-pane {
        font-size: 12px;
        font-weight: 500;

        .label {
            opacity: 0.6;
        }
    }

    #content {
        @include media-breakpoint-down(sm) {
            padding-top: 140px;
        }

        .feed-title-input {
            padding: 8px 16px;
            border-radius: 8px;
            border: solid 1px $border-light;
            font-size: 18px;
            font-weight: 500;
        }

        .bottom-border {
            border-bottom: 1px solid $border-light;
        }

        h2 {
            font-size: 16px;
            font-weight: 500;
            margin-bottom: 16px;
            margin-top: 32px;
        }

        .feed-tender-empty-row {
            justify-content: center;
            text-align: center;
            color: $body-color;
            opacity: 0.6;
            padding-top: 20px;
            padding-top: 20px;
        }

        .next-tenders-button {
            margin: 20px auto;
            text-align: center;
        }

        .feed-filter-field {
            padding: 24px 0;
        }

        .edit-mode-additional-filter {
            font-size: 12px;
            font-weight: 500;
            padding-top: 16px;
            padding-bottom: 16px;

            @include media-breakpoint-up(md) {
                font-size: 14px;
            }
        }
    }

    .content-loader {
        height: 100vh;
    }
}
</style>

<style lang="scss">
@import "src/scss/custom";

#content .edit-mode-additional-filter {
    .value-list-item {
        border-radius: 10px;
        background-color: transparent;

        &:hover,
        &.selected {
            background-color: $background-light;
        }

        span {
            padding-left: 5px;
            opacity: 0.6;
        }
    }
}

.menu-item-feed .loader .icon {
    font-size: 10px !important;
}
</style>