
<div>
    <AlertBubble
        :show="showSeedPopulationBubble && !isAlertDismissed"
        @dismissed="dismissAlert"
    >
        {{ $t("feed.populate.text") }}

        <template #button>
            <button class="btn btn-light update" @click="$bvModal.show('populate-seed-modal')">
                {{ $t("feed.populate.btn") }}
            </button>
        </template>
    </AlertBubble>

    <ConfirmModal
        id="populate-seed-modal"

        @ok="populateSeed"

        :title="$t('settings.populateSeed.confirm.title')"
        :text="$t('settings.populateSeed.confirm.body')"
        :ok-btn-label="$t('settings.populateSeed.confirm.ok')"
        :cancel-btn-label="$t('settings.populateSeed.confirm.cancel')"
        ok-btn-variant="dark"
    />
</div>
