<template>
    <div>
        <header>
            <div class="container-fluid">
                <nav class="grid-row nav">
                    <div class="grid-col">
                        <b-navbar>
                            <b-navbar-brand :to="{ name: 'home' }" class="logo">
                                <icon icon="datlab" />
                                <div>{{ $t('tendry') | upper }}</div>
                            </b-navbar-brand>

                            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                            <b-collapse id="nav-collapse" is-nav class="justify-content-end">
                                <slot name="nav">
                                    <b-navbar-nav></b-navbar-nav>
                                    <b-navbar-nav class="ml-auto">
                                        <b-nav-item
                                            v-for="(n, k) in nav"
                                            :key="`nav-${k}`"
                                            exact
                                            exact-active-class="active"
                                            active-class="active"
                                            v-bind="n"
                                        >
                                            {{ $t(`nav.${k}`) | upper }}
                                        </b-nav-item>
                                    </b-navbar-nav>
                                </slot>
                            </b-collapse>
                        </b-navbar>
                    </div>
                </nav>

                <div class="grid-row no-gutters site-description">
                    <div class="grid-col text-center"></div>
                </div>
            </div>
        </header>

        <main>
            <slot v-if="blankMainSection"></slot>
            <grid-row v-else>
                <slot></slot>
            </grid-row>
        </main>

        <footer id="claim" class="text-left" v-if="!noFooter">
            <div class="line-logo">
                <icon icon="tendry" />
            </div>

            <grid-row>
                <div class="row">
                    <div class="col">
                        <div class="footer-icon">
                            <icon icon="big-d" /><span>T</span>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col-lg-5 col-xl-4 claim">
                        <p>{{ $t('footer.claim.0') }}</p>
                        <p>{{ $t('footer.claim.1') }}</p>
                    </div>

                    <div class="col-auto contact">
                        <h4>{{ $t('footer.contact') | upper }}</h4>
                        <p class="address">
                            {{ $t('footer.address.name') }}<br />
                            {{ $t('footer.address.street') }}<br />
                            {{ $t('footer.address.city') }}<br />
                            {{ $t('footer.address.country') }}<br />
                        </p>
                        <div class="mb-0 vat_id">
                            <div>{{ $t('footer.address.vatId') }}</div>
                        </div>
                    </div>

                    <div class="col-auto links">
                        <h4>{{ $t('footer.products') | upper }}</h4>
                        <a href="https://tenderman.cz/" target="_blank">Datlab Tenderman</a>
                        <a href="https://kontrola.datlab.eu/" target="_blank">Datlab Kontrola</a>
                        <a href="https://www.zindex.cz/" target="_blank">Zindex</a>
                    </div>

                    <div class="col-auto others">
                        <h4>{{ $t('footer.others') | upper }}</h4>
                        <a href="https://www.facebook.com/datlab.cz" target="_blank">Facebook</a>
                        <a href="https://datlab.cz" target="_blank">Datlab</a>
                        <a href="https://datlabinstitut.cz" target="_blank">Datlab Institut</a>
                    </div>
                </div>
            </grid-row>
        </footer>

        <footer id="mandatory-info" class="text-left" v-if="!noFooter">
            <grid-row>
                <div class="row justify-content-between">
                    <div class="col">{{ $t('footer.copyright') }}</div>
                    <div class="col col-auto">{{ $t('email') }}</div>
                </div>
            </grid-row>
        </footer>
    </div>
</template>

<script>
export default {
    props: {
        blankMainSection: { type: Boolean, default: false },
        noFooter: { type: Boolean, default: false }
    },
    created: function() {
    },
    computed: {
        nav: function () {
            return {
            }
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/custom";

.logo {
    .icon + div {
        color: $color-red;
    }
}
</style>