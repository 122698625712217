import Vue from 'vue'
import App from './App.vue'
import i18n from "./messages"
import router from './router'
import Base from '@/views/layout/Base.vue'
import Feeds from '@/views/layout/Feeds.vue'
import Auth from '@/views/layout/Auth.vue'
import Evaluation from '@/views/layout/Evaluation.vue'
import GridRow from '@/components/GridRow.vue'
import Icon from "@/components/Icon.vue"
import Loader from "@/components/Loader.vue"
import "./filters"
import VueGtm from '@gtm-support/vue2-gtm'
import {CONFIG} from './config.js'
import store from "@/store";
import VueMoment from 'vue-moment'
import RouterButton from "@/components/RouterButton.vue"
import Multiselect from 'vue-multiselect'
import PasswordInput from "@/components/PasswordInput.vue"
import VueTour from 'vue-tour'
import OptiImagePlugin from 'opti-image'
import '@/scss/_vuetour.scss'
import { VuePlausible } from 'vue-plausible'

Vue.config.productionTip = false

if (CONFIG.gtmkey) {
  Vue.use(VueGtm, {
      id: CONFIG.gtmkey,
      vueRouter: router,
      enabled: !!CONFIG.gtmkey
  });
}

// specific boostrap-vue imports
import { NavPlugin, NavbarPlugin, LayoutPlugin, ButtonPlugin, ModalPlugin, SpinnerPlugin, CollapsePlugin,
         TooltipPlugin, FormCheckboxPlugin, ProgressPlugin, AlertPlugin, CardPlugin, FormPlugin,
         FormInputPlugin, FormGroupPlugin, BTable, PaginationPlugin } from 'bootstrap-vue'

Vue.use(CardPlugin)
Vue.use(NavPlugin)
Vue.use(NavbarPlugin)
Vue.use(LayoutPlugin)
Vue.use(ButtonPlugin)
Vue.use(ModalPlugin)
Vue.use(SpinnerPlugin)
Vue.use(CollapsePlugin)
Vue.use(TooltipPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(ProgressPlugin)
Vue.use(AlertPlugin)
Vue.use(FormPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(PaginationPlugin)
Vue.component("b-table", BTable)


Vue.use(VueTour)
Vue.use(OptiImagePlugin, {})

Vue.use(VuePlausible, {
    domain: CONFIG.plausibleDomain,
    trackLocalhost: true,
    apiHost: CONFIG.plausibleTrackingApi
})

Vue.$plausible.enableAutoOutboundTracking()
Vue.$plausible.enableAutoPageviews()

Vue.component('layout', Base)
Vue.component('layout-feeds', Feeds)
Vue.component('layout-evaluation', Evaluation)
Vue.component('layout-auth', Auth)
Vue.component('grid-row', GridRow)
Vue.component('icon', Icon)
Vue.component('loader', Loader)
Vue.component('router-button', RouterButton)
Vue.component('password-input', PasswordInput)
Vue.component('multiselect', Multiselect)


import moment from 'moment'
moment.locale('cs-CZ');

var relativeTimeFomatter = (number, withoutSuffix, key, isFuture) => {
    return i18n.tc(`relativeTime.${key}${isFuture ? "" : "_past"}`, number);
}

moment.updateLocale('cs-CZ', {
    months: {
        standalone: 'ledna_února_března_dubna_května_června_července_srpna_září_října_listopadu_prosince'.split('_'),
        format: 'leden_únor_březen_duben_květen_červen_červenec_srpen_září_říjen_listopad_prosinec'.split('_'),
    },
    monthsShort : 'led_úno_bře_dub_kvě_čvn_čvc_srp_zář_říj_lis_pro'.split('_'),
    relativeTime: {
        future: function (number) {
            if (number == i18n.t("relativeTime.d")) {
                number = i18n.tc("relativeTime.dd", 1)
            }

            return i18n.tc(`relativeTime.future`, parseInt(number), {s: number});
        },
        past: function (number) {
            if (number == i18n.t("relativeTime.d_past")) {
                return number
            }

            return i18n.t("relativeTime.past", {s: number})
        },
        s: relativeTimeFomatter,
        ss: relativeTimeFomatter,
        m: relativeTimeFomatter,
        mm: relativeTimeFomatter,
        h: relativeTimeFomatter,
        hh: relativeTimeFomatter,
        d: relativeTimeFomatter,
        dd: relativeTimeFomatter,
        M: relativeTimeFomatter,
        MM: relativeTimeFomatter,
        y: relativeTimeFomatter,
        yy: relativeTimeFomatter
    },
    longDateFormat : {
        L : 'DD.MM.YYYY',
        l : 'D. M. YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        lll : 'D. M. YYYY HH:mm'
    }
})

Vue.use(VueMoment, {moment})

Vue.prototype.$searchToFeedConfig = function(search) {
    let config = {}

    if (search) {
        search.forEach(n => {
            if (typeof n === 'string' || n instanceof String) {
                config.keywords = config.keywords || []
                config.keywords.push(n)
                return
            }

            switch (n.tag) {
                case "cpv":
                    config.cpvs = config.cpvs || []
                    config.cpvs.push(n.value)
                    break
                case "region":
                    config.regions = config.regions || []
                    config.regions.push(n.value)
                    break
                case "buyer":
                    config.buyers = config.buyers || []
                    config.buyers.push(n.value)
                    break
                case "priceFrom":
                    config.price = config.price || {}
                    config.price.from = parseFloat(n.value)
                    break
                case "priceTo":
                    config.price = config.price || {}
                    config.price.to = parseFloat(n.value)
                    break
                default:
                    return
            }
        })
    }

    return config
}

Vue.prototype.$feedConfigToSearch = function(config) {
    var search = []

    if (config) {
        if (config.keywords) {
            config.keywords.forEach(n => search.push(n))
        }

        if (config.cpvs) {
            config.cpvs.forEach(n => search.push({tag: "cpv", value: n}))
        }

        if (config.regions) {
            config.regions.forEach(n => search.push({tag: "region", value: n}))
        }

        if (config.price) {
            if (config.price.from) {
                search.push({tag: "priceFrom", value: parseFloat(config.price.from)})
            }
            if (config.price.to) {
                search.push({tag: "priceTo", value: parseFloat(config.price.to)})
            }
        }

        if (config.buyers) {
            config.buyers.forEach(n => search.push({tag: "buyer", value: n}))
        }
    }

    return search
}

Vue.prototype.$arrayToggle = function(value, arr) {
    var index = arr.indexOf(value)
    if (index < 0) {
        arr.push(value)
        return true
    } else {
        arr.splice(index, 1)
    }

    return false
}

Vue.prototype.$arrayPop = function(value, arr) {
    var index = arr.indexOf(value)
    if (index < 0) {
        return
    }

    arr.splice(index, 1)
}

Vue.prototype.$unaccent = function(str) {
    return str ? str.normalize("NFD").replace(/\p{Diacritic}/gu, "") : str
}

Vue.prototype.$equal = function(a, b) {
    var sort = (obj) => {
        if(obj == null || typeof obj !== 'object' || Array.isArray(obj)) {
            return obj;
        }

        return Object.keys(obj).sort().reduce((r, k) => (r[k] = sort(obj[k]), r), {});
    }

    // fix numbers
    var replacer = (k, v) => {
        return !isNaN(v) && typeof v === 'string' ? parseFloat(v) : v
    }

    return JSON.stringify(sort(a), replacer) == JSON.stringify(sort(b), replacer)
}

Vue.prototype.$cleanTendersRequestParams = function(obj) {
    // subject, competitors, and tenders are not in filters
    delete obj.subject
    delete obj.competitors
    delete obj.tenders

    // remove empty arrays and nulls
    Object.keys(obj).forEach(k => {
        if (obj[k] === null || (Array.isArray(obj[k]) && !obj[k].length)) {
            delete obj[k]
        }
    })
}

Vue.prototype.$equalTendersRequestParams = function(a, b) {
    if (a == null || b == null) {
        return false
    }

    // deep clone
    var aa = JSON.parse(JSON.stringify(a))
    var bb = JSON.parse(JSON.stringify(b))

    if (aa.settings) {
        this.$cleanTendersRequestParams(aa.settings)
    }

    if (bb.settings) {
        this.$cleanTendersRequestParams(bb.settings)
    }

    return this.$equal(aa, bb)
}

Vue.prototype.$scroll = function() {
    var pos = this.$store.getters.scrollPosition

    if (pos) {
        window.scroll({
            top: pos.y,
            left: pos.x,
            behavior: 'instant'
        })
    }
}

Vue.prototype.$log = function(type, data) {
    return this.$store.getters.api
        .post("applog/add/", {
            type: type,
            data: data
        })
}

Vue.directive('visible', function(el, binding) {
    el.style.visibility = binding.value ? 'visible' : 'hidden'
})

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app')
