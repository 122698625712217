import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {CONFIG} from '@/config.js'
import router from '@/router'
import md5 from "md5"

const api = axios.create({
    baseURL: CONFIG.apiBaseUrl,
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

api.interceptors.response.use(
    function(response) { return response },
    function(error) {
        if (error.response?.status == 403) {
            router.push({name: "permission-denied"})
        }

        return Promise.reject(error)
    }
)

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
       email: null,
       signupFinished: false,
       user: null,
       feeds: null,
       regions: [],
       feedsStats: null,
       searchFilter: [],
       cancelToken: {},
       tenders: null,
       hasTendersNextPage: true,
       tendersParams: {},
       tendersStats: null,
       feed: null,
       scrollPosition: null,
       editModeActive: false,
       userActivated: false,
       passwordReseted: false,
       newsCount: 0,
       subjectUpdated: false,
       newLikesCount: 0,
       populationAlertDismissed: false,
       noCompanyAlertDismissed: false,
       userMenuExpanded: false,
       latestTendersStats: null,
       isLatestTendersStatsBusy: false,
       latestTendersAlertDismissed: false,
    },
    getters: {
        email: state => state.email,
        signupFinished: state => state.signupFinished,
        api: () => api,
        user: state => state.user,
        user_uid: state => {
            if (!state.user) {
                return undefined
            }
            return md5(state.user.email)
        },
        loggedIn: state => !!state.user,
        feeds: state => state.feeds,
        showFeedConfiguratorAfterLogin: (state, getters) => {
            return !state.feeds || !state.feeds.length || !getters.smartFeed
        },
        regions: state => state.regions,
        feedsStats: state => state.feedsStats,
        smartFeed: state => {
            if (!state.feeds) {
                return undefined
            }
            return state.feeds.find(n => n.smart)
        },
        toursToShow: state => {
            if (state.user) {
                return state.user.tours_to_show
            }

            return undefined
        },
        searchFilter: state => state.searchFilter,
        cancelToken: state => id => {
            return state.cancelToken[id]
        },
        tenders: state => state.tenders,
        tendersParams: state => state.tendersParams,
        tendersStats: state => state.tendersStats,
        feed: state => state.feed,
        scrollPosition: state => state.scrollPosition,
        editModeActive: state => state.editModeActive,
        hasTendersNextPage: state => state.hasTendersNextPage,
        userActivated: state => state.userActivated,
        passwordReseted: state => state.passwordReseted,
        newsCount: state => state.newsCount,
        subjectUpdated: state => state.subjectUpdated,
        isSmartFeedPopulated: (state, getters) => getters.smartFeed && getters.smartFeed.populated,
        isSeedPopulationEnabled: (state, getters) => getters.isSmartFeedPopulated && (getters.subjectUpdated || !!getters.newLikesCount),
        newLikesCount: state => state.newLikesCount,
        feedPopulationInProgress: (state, getters) =>
            getters.smartFeed && !getters.smartFeed.populated && getters.smartFeed.population_status < 100,
        feedPopulationStatus: (state, getters) =>{
            if (!getters.smartFeed) {
                return 0
            }

            return getters.smartFeed.population_status || 0
        },
        populationAlertDismissed: state => state.populationAlertDismissed,
        noCompanyAlertDismissed: state => state.noCompanyAlertDismissed,
        userMenuExpanded: state => state.userMenuExpanded,
        isTourEnabled: (state, getters) => (key) => {
            // show discount independent on the viewport size
            if (key == "discount" && getters.toursToShow[key] === true) {
                return true
            }
            // some tours are shown over the left-side menu
            // for viewports narrower than 1200px these tours are disabled
            // 1200px is css media breaking point for hiding left-side menu
            if (key in ["smart", "feed", "news"] && window.innerWidth < 1200) {
                return false
            }

            return !getters.isMobile && getters.toursToShow && getters.toursToShow[key] === true
        },
        isMobile: () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent),
        latestTendersStats: (state) => state.latestTendersStats,
        isLatestTendersStatsBusy: (state) => state.isLatestTendersStatsBusy,
        latestTendersAlertDismissed: state => state.latestTendersAlertDismissed
    },
    mutations: {
        setEmail: function (state, email) {
            state.email = email
        },
        setSignupFinished: function (state, finished) {
            state.signupFinished = finished
        },
        setUser: function (state, user) {
            state.user = user
        },
        setFeeds: function (state, feeds) {
            state.feeds = feeds
        },
        setNewTendersCount: function (state, payload) {
            var feed_id = payload.feed_id
            var count = payload.count

            if (!state.feedsStats || !(feed_id in state.feedsStats)) {
                return
            }

            state.feedsStats[feed_id]["new_count"] = count

            state.feedsStats = {...state.feedsStats}
        },
        setRegions: function (state, value) {
            state.regions = value
        },
        setFeedsStats: function (state, value) {
            state.feedsStats = value
        },
        setTours: function (state, value) {
            if (state.user) {
                state.user.tours_to_show = value
            }
        },
        setSearchFilter: function (state, value) {
            state.searchFilter = value
        },
        setCancelToken: (state, id) => {
            state.cancelToken[id] = axios.CancelToken.source()
        },
        setTenders: (state, tenders) => {
            state.tenders = tenders
        },
        setTendersParams: (state, params) => {
            state.tendersParams = params
        },
        setTendersStats: (state, stats) => {
            state.tendersStats = stats
        },
        setFeed: (state, feed) => {
            state.feed = feed

            if (feed && state.feeds) {
                var index = state.feeds.findIndex(f => f.id == feed.id)
                if (index > -1) {
                    state.feeds.splice(index, 1, feed)
                }
            }
        },
        setFeedFavorite: (state, favorite) => {
            if (state.feed) {
                state.feed.favorite = favorite
            }
        },
        markTenderAsSeen: function (state, tender_id) {
            if (!state.tenders) {
                return
            }

            var tender = state.tenders.find(t => t.id == tender_id)
            if (tender) {
                tender.seen = true
            }
        },
        setScrollPosition: function (state, position) {
            state.scrollPosition = position
        },
        setEditModeActive: function (state, active) {
            state.editModeActive = active
        },
        likeTender: function(state, payload) {
            var like = payload.like
            var tender = payload.tender

            var price = tender.price || tender.estimated_price
            price = price ? parseFloat(price) : 0

            // update new likes count
            var seen = tender.seen
            if (like === true) {
                state.newLikesCount += 1
            } else if (state.newLikesCount) {
                state.newLikesCount -= 1
            }

            // update liked tenders stats
            if (state.tendersStats != null) {
                if (like === true) {
                    state.tendersStats.like_count += 1
                    state.tendersStats.like_amount += price
                    if (!seen) {
                        state.tendersStats.like_new_count += 1
                    }
                } else {
                    state.tendersStats.like_count -= 1
                    state.tendersStats.like_amount -= price
                    if (!seen) {
                        state.tendersStats.like_new_count -= 1
                    }
                }
            }

            // update tender in page of already loaded tenders
            if (state.tenders) {
                let t = state.tenders.find(n => n.id == tender.id)
                if (t) {
                    t.like = like
                }
            }
        },
        setHasTendersNextPage: function(state, hasNextPage) {
            state.hasTendersNextPage = hasNextPage
        },
        setSmartFeed: function(state, smartFeed) {
            var feeds = state.feeds || []

            var index = state.feeds.findIndex(n => n.smart)
            if (index > -1) {
                feeds.splice(index, 1, smartFeed)
            } else {
                feeds.push(smartFeed)
            }

            state.feeds = feeds
        },
        setUserActivated: function(state, activated) {
            state.userActivated = activated
        },
        setPasswordReseted: function(state, reseted) {
            state.passwordReseted = reseted
        },
        setNewsCount: function(state, count) {
            state.newsCount = count
        },
        setUserSendNotifications: function(state, value) {
            if (state.user) {
                state.user.send_notifications = value
            }
        },
        setSubjectUpdated: function(state, value) {
            state.subjectUpdated = value
        },
        setNewLikesCount: function(state, value) {
            state.newLikesCount = value
        },
        setPopulationAlertDismissed: function(state, value) {
            state.populationAlertDismissed = value
        },
        setNoCompanyAlertDismissed: function(state, value) {
            state.noCompanyAlertDismissed = value
        },
        setUserMenuExpanded: function(state, value) {
            state.userMenuExpanded = value
        },
        setLatestTendersStats: function(state, value) {
            state.latestTendersStats = value
        },
        setIsLatestTendersStatsBusy: function(state, value) {
            state.isLatestTendersStatsBusy = value
        },
        setLatestTendersAlertDismissed: function(state, value) {
            state.latestTendersAlertDismissed = value
        }
    },
    actions: {
        loadUser({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.user) {
                    resolve(state.user)
                    return
                }

                api.get("user/")
                    .then(r => {
                        commit("setUser", r.data)
                        resolve(r.data)
                    })
                    .catch(e => {
                        commit("setUser", null)
                        reject(e)
                    })
            })
        },
        loadFeeds({commit, state, getters}, force) {
            return new Promise((resolve, reject) => {
                if (state.feeds && !force) {
                    resolve(state.feeds)
                    return
                }

                if (getters.cancelToken("feeds") != null) {
                    getters.cancelToken("feeds").cancel("Operation canceled by the user.");
                }

                commit("setCancelToken", "feeds")

                api.get("feed/", {cancelToken: getters.cancelToken("feeds").token})
                    .then(r => {
                        commit("setFeeds", r.data)
                        resolve(r.data)
                    })
                    .catch(e => {
                        commit("setFeeds", null)
                        reject(e)
                    })
            })
        },
        loadFeedsStats({commit, state, getters}, force) {
            return new Promise((resolve, reject) => {
                if (state.feedsStats && !force) {
                    resolve(state.feedsStats)
                    return
                }

                if (getters.cancelToken("feed-stats") != null) {
                    getters.cancelToken("feed-stats").cancel("Operation canceled by the user.");
                }

                commit("setCancelToken", "feed-stats")

                api.get("feeds-stats/", {cancelToken: getters.cancelToken("feed-stats").token})
                    .then(r => {
                        commit("setFeedsStats", r.data)
                        resolve(r.data)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },
        updateFeedsStats({ dispatch }) {
            return dispatch("loadFeedsStats", true)
        },
        loadRegions({commit, state}) {
            return new Promise((resolve, reject) => {
                if (state.regions.length) {
                    resolve(state.regions)
                    return
                }

                api.get("region/")
                    .then(r => {
                        commit("setRegions", r.data.results)
                        resolve(r.data.results)
                    })
                    .catch(e => {
                        commit("setRegions", [])
                        reject(e)
                    })
            })
        },
        /***
         * Updates tours according to state.
         */
        updateTours({commit}, tours) {
            return new Promise((resolve, reject) => {
                api.post("tours/", {tours_to_show: tours})
                    .then(r => {
                        commit("setUser", r.data)
                        resolve(r.data)
                    })
                    .catch(e => reject(e))
            })
        },
        /**
         * Logouts user and clears user data.
         */
        logout({dispatch}) {
            return new Promise((resolve, reject) => {
                this.state.user = null;
                api.get("/logout")
                    .then(r => {
                        resolve(r);
                        dispatch("clearUserData")
                        window.location.replace(CONFIG.landingPageUrl);
                    })
                    .catch(e => {
                            console.log(e)
                            reject(e);
                        }
                    );
            })
        },
        /**
         * Removes feed, reloads stats and redirects to smart feed
         */
        removeFeed ({dispatch}, id) {
            return new Promise((resolve, reject) => {
                api
                    .delete(`feed/${id}/`)
                    .then(() => {
                        dispatch("loadFeeds", true)
                            .then(r => resolve(r))
                            .catch(e => reject(e));
                        router.push({name: "feed-detail", params: {id: this.getters.smartFeed.id}})
                    })
                    .catch(e => reject(e))
            })
        },
        createFeed(_,  settings) {
            return new Promise((resolve, reject) => {
                api
                    .post("create-feed/", settings)
                    .then(r => resolve(r.data))
                    .catch(e => {
                        console.log(e)
                        reject(e)
                    })
            })
        },
        /**
         * Clears state and user data.
         */
        clearUserData({commit}) {
            commit("setEmail", "")
            commit("setUser", null)
            commit("setFeeds", null)
            commit("setFeedsStats", null)
            commit("setSearchFilter", null)
        },
        loadTenders: function ({commit, getters, dispatch}, options) {
            return new Promise((resolve, reject) => {
                var params = options.params
                var append = options.append
                var feed_id = options.feed_id
                var tender_news = options.news || false
                var endpoint
                if (tender_news) {
                    endpoint = "news/"
                } else {
                    endpoint = feed_id ? `feed/${feed_id}/tender/` : `tender/`
                }

                if (getters.cancelToken("tenders") != null) {
                    getters.cancelToken("tenders").cancel("Operation canceled by the user.");
                }

                commit("setCancelToken", "tenders")

                getters.api.post(endpoint, params, {cancelToken: getters.cancelToken("tenders").token})
                .then(r => {
                    commit("setTendersParams", params)

                    commit("setHasTendersNextPage", r.data.results.length == r.data.page_size)

                    if (append && getters.tenders) {
                        commit("setTenders", getters.tenders.concat(r.data.results))
                    } else {
                        commit("setTenders", r.data.results)

                        // don't load latest tenders stats if the allert is dismissed
                        if (!getters.latestTendersAlertDismissed) {
                            dispatch("loadLatestTendersStats", {endpoint: `${endpoint}latest_stats/`, params: params})
                        }
                    }

                    resolve(r)
                })
                .catch(e => {
                    commit("setTendersParams", {})
                    commit("setTenders", [])
                    console.log(e)
                    reject(e)
                })
            })
        },
        loadTendersStats: function({commit, getters}, options) {
            return new Promise((resolve, reject) => {
                var params = options.params
                var feed_id = options.feed_id
                var tender_news = options.news || false

                var endpoint
                if (tender_news) {
                    endpoint = "news-stats/"
                } else if (feed_id) {
                    endpoint = `feed/${feed_id}/tenders-stats/`
                } else {
                    endpoint = "tenders-stats/"
                }

                if (getters.cancelToken("tenders-stats") != null) {
                    getters.cancelToken("tenders-stats").cancel("Operation canceled by the user.");
                }

                commit("setCancelToken", "tenders-stats")

                return getters.api
                    .post(endpoint, params, {cancelToken: getters.cancelToken("tenders-stats").token})
                    .then(r => {
                        commit("setTendersStats", r.data)
                        resolve(r)
                    })
                    .catch(e => {
                        commit("setTendersStats", null)
                        console.log(e)
                        reject(e)
                    })
            })
        },
        loadFeed: function({commit, getters}, options) {
            return new Promise((resolve, reject) => {
                var force = options.force
                var feed_id = options.feed_id

                if (getters.feed && !force && feed_id == getters.feed.id) {
                    resolve(getters.feed)
                    return
                }

                if (getters.cancelToken("feed-detail") != null) {
                    getters.cancelToken("feed-detail").cancel("Operation canceled by the user.");
                }

                commit("setCancelToken", "feed-detail")

                return getters.api
                    .get(`feed/${feed_id}/`, {
                        cancelToken: getters.cancelToken("feed-detail").token
                    })
                    .then(r => {
                        commit("setFeed", r.data)
                        resolve(r)
                    })
                    .catch(e => {
                        commit("setFeed", null)
                        console.log(e)
                        reject(e)
                    })
            })
        },
        decreaseNewTendersCount: function ({commit, getters}, {feed, opened, liked}) {
            // update count of new tenders in feed stats
            if (getters.feedsStats && (feed in getters.feedsStats) && getters.feedsStats[feed]["new_count"]) {
                var count = getters.feedsStats[feed]["new_count"]
                commit("setNewTendersCount", {feed_id: feed, count: count ? count-- : 0})
            }

            // update counts of new tenders in tenders stats
            var stats = getters.tendersStats
            if (stats) {
                if (stats.new_count) {
                    stats.new_count -= 1
                }

                if (liked && stats.like_new_count) {
                    stats.like_new_count -= 1
                }

                if (opened && stats.open_new_count) {
                    stats.open_new_count -= 1
                }
            }
            commit("setTendersStats", stats)

            // update news count
            commit("setNewsCount", getters.newsCount ? getters.newsCount - 1 : 0)
        },
        resetNewTendersCount: function ({commit, getters}, feed_id) {
            // reset new tenders count of all feeds
            if (feed_id == null) {
                Object.values(getters.feedsStats).forEach(n => n.new_count = 0)
                commit("setFeedsStats", {...getters.feedsStats})
                return
            }

            if (!getters.feedsStats || !(feed_id in getters.feedsStats)) {
                return
            }

            commit("setNewTendersCount", {feed_id: feed_id, count: 0})
        },
        refreshSmartFeed: function({commit, getters}) {
            return new Promise(function(resolve, reject) {
                if (!getters.smartFeed) {
                    resolve(null)
                }

                api.get(`feed/${getters.smartFeed.id}/`)
                    .then(r => {
                        commit("setSmartFeed", r.data)
                        resolve(r.data)
                    })
                    .catch(e => {
                        reject(e)
                    })
            })
        },
        updateNewsCount: function({getters, commit}) {
            return new Promise((resolve, reject) => {
                getters.api.get(`news-count/`)
                    .then(r => {
                        commit("setNewsCount", r.data)
                        resolve(r.data)
                    })
                    .catch(e => {
                        commit("setNewsCount", 0)
                        reject(e)
                    })
                })
        },
        updateNewLikesCount: function({getters, commit}) {
            return new Promise((resolve, reject) => {
                getters.api.get(`likes-count/`)
                    .then(r => {
                        commit("setNewLikesCount", r.data)
                        resolve(r.data)
                    })
                    .catch(e => {
                        commit("setNewLikesCount", 0)
                        reject(e)
                    })
            })
        },
        populateSmartFeedSeed: function({getters, commit, dispatch}) {
            return new Promise((resolve, reject) => {
                if (!getters.isSeedPopulationEnabled) {
                    resolve()
                }

                commit("setSmartFeed", {...getters.smartFeed, ...{populated: false, population_status: 0}})
                commit("setSubjectUpdated", false)
                commit("setNewLikesCount", 0)

                getters.api.get(`populate/${getters.smartFeed.id}/`)
                    .then(() => resolve())
                    .catch(e => reject(e))
                    .finally(() => dispatch("refreshSmartFeed"))
            })
        },
        updateSmartFeedSubject: function({getters, dispatch, commit}, subject) {
            return new Promise((resolve, reject) => {
                if (!getters.smartFeed) {
                    resolve()
                }

                commit("setSubjectUpdated", true)

                getters.api
                    .patch(`feed/${getters.smartFeed.id}/`, {settings: {subject: subject}, remove_old_notification: true})
                    .then(() => {
                        dispatch("refreshSmartFeed")
                        resolve()
                    })
                    .catch(e => {
                        commit("setSubjectUpdated", false)
                        reject(e)
                    })
            })
        },
        dismissPopulationAlert: function({commit}) {
            commit("setPopulationAlertDismissed", true)
        },
        dismissNoCompanyAlert: function({commit}) {
            commit("setNoCompanyAlertDismissed", true)
        },
        loadLatestTendersStats: function ({commit, getters}, options) {
            return new Promise((resolve, reject) => {
                var endpoint = options.endpoint
                var params = options.params

                commit("setIsLatestTendersStatsBusy", true)

                getters.api.post(endpoint, params, {cancelToken: getters.cancelToken("tenders").token})
                    .then(r => {
                        commit("setLatestTendersStats", r.data)
                        resolve()
                    })
                    .catch(e => {
                        commit("setLatestTendersStats", null)
                        console.log(e)
                        reject(e)
                    })
                    .finally(() => commit("setIsLatestTendersStatsBusy", false))
            })
        },
        dismissLatestTendersAlert: function({commit}) {
            commit("setLatestTendersAlertDismissed", true)
        }
    },
    modules: {},
})
