
<div>
    <b-container fluid id="feeds-layout">
        <b-row>
            <b-col id="side-menu" cols="auto" :class="{expanded: sideMenuExpanded}">
                <div class="side-menu-inner-sticky d-flex flex-column">
                    <div class="menu-upper-section flex-shrink-0">
                        <div class="logo">
                            <div>
                                <icon icon="datlab"/>
                                <div>{{ $t('tendry') | upper }}</div>
                            </div>
                            <button class="close-button" @click="toggleSideMenu(false)">
                                <icon icon="x"/>
                            </button>
                        </div>

                        <div class="user" v-if="user">
                            <div class="menu-item pr-0">
                                <div class="d-inline-block text-truncate" id="email">
                                    <icon icon="user"/>{{ user.email }}
                                </div>
                                <b-tooltip target="email" triggers="hover" boundary-padding="2">
                                    {{ user.email }}
                                </b-tooltip>
                                <button @click="() => toggleUserMenu()" class="btn menu-toggler">
                                    <icon v-if="userMenuExpanded" icon="triangle" down/>
                                    <icon v-else icon="triangle" up/>
                                </button>
                            </div>
                            <ul class="user-menu" v-if="userMenuExpanded">
                                <li>
                                    <router-button :to="{ name: 'settings' }" class="menu-item">
                                        <icon icon="settings"/>
                                        {{ $t("settings.nav") }}
                                    </router-button>
                                </li>
                                <li>
                                    <router-button :to="{ name: 'subscription' }" class="menu-item">
                                        <icon icon="candy"/>
                                        {{ $t("subscription.nav") }}
                                    </router-button>
                                </li>
                                <li>
                                    <router-button :to="{ name: 'logout' }" class="menu-item">
                                        <icon icon="logout"/>
                                        {{ $t("auth.logout.button") }}
                                    </router-button>
                                </li>
                            </ul>
                        </div>

                        <div class="menu-item trial" v-if="user.subscription_type == 'SubscriptionType.TRIAL'">
                            <p>
                                {{ $t("subscription.trial.info", { until: $moment(user.subscribed_until).format("D.M.") }) }}
                            </p>

                            <router-button :to="{name: 'subscription'}">
                                {{ $t("subscription.trial.btn") }}
                            </router-button>
                        </div>

                        <router-button class="menu-item" :to="{name: 'search'}">
                            <icon icon="search"/>
                            {{ $t('feeds.menu.search') }}
                        </router-button>

                        <router-button class="menu-item menu-item-count" :to="{name: 'news'}">
                            <icon icon="news"/>
                            <span>{{ $t('feeds.menu.news') }}</span>
                            <span class="new-count">
                                <loader v-if="newsCountBusy" small />
                                <template v-else>{{ newsCount }}</template>
                            </span>
                        </router-button>

                        <span v-if="favorites && favorites.length">
                            <h5>
                                {{ $t("feeds.menu.favourites") | upper }}
                            </h5>
                            <loader v-if="feedsBusy" centered small/>
                            <template v-else-if="favorites && favorites.length">
                                <router-button
                                    v-for="f in favorites" :key="`favorite-${f.id}`"
                                    class="menu-item menu-item-feed"
                                    :to="{name: 'feed-detail', params: {id: f.id}}"
                                >
                                    <icon icon="point"/>
                                    <span>{{ f.title }}</span>
                                </router-button>
                            </template>
                            <div v-else class="empty-menu-item">
                                {{ $t("feeds.menu.empty") }}
                            </div>
                        </span>

                        <h5>
                            <div>{{ $t('feeds.menu.smartFeed') | upper }}</div>
                        </h5>
                        <loader v-if="feedsBusy" centered small />
                        <template v-else-if="smartFeed">
                            <router-button
                                class="menu-item menu-item-count"
                                id="tour-smart"
                                :to="{name: 'feed-detail', params: {id: smartFeed.id}}"
                                :active="$route.name == 'feed-complete'"
                                :deactive="$route.name == 'dashboard'"
                            >
                                <icon icon="atom"/>
                                <span>{{ $t("feeds.menu.smartFeedAlt") }}</span>
                                <span class="new-count">
                                    <loader v-if="feedsStatsBusy" small />
                                    <template v-else-if="smartFeed.id in feedsStats">{{ feedsStats[smartFeed.id].new_count }}</template>
                                    <template v-else>0</template>
                                </span>
                            </router-button>

                            <router-button class="menu-item" :to="{name: 'dashboard', params: {id: smartFeed.id}}">
                                <icon icon="dashboard"/>
                                <span>{{ $t("feeds.menu.dashboard") }}</span>
                            </router-button>
                        </template>

                        <h5>
                            <div>{{ $t('feeds.menu.feeds') | upper }}</div>
                            <button
                                id="tour-feed"
                                @click="$router.push({name: 'feed-create'})"
                                class="btn menu-toggler"
                                :disabled="
                                    $router.currentRoute.name == 'feed-create'
                                "
                            >
                                <icon icon="plus" class="btn-create-feed" />
                            </button>
                        </h5>
                        <loader v-if="feedsBusy" centered small />
                        <template v-else-if="feedsWithoutSmart && feedsWithoutSmart.length">
                            <router-button
                                v-for="(f, i) in feedsWithoutSmart"
                                :key="`feed-${f.id}`"
                                :id="`feed-${f.id}`"
                                class="menu-item menu-item-feed menu-item-count"
                                :to="{name: 'feed-detail', params: {id: f.id}}"
                            >
                                <icon icon="point"/>
                                <span>{{ f.title }}</span>
                                <span class="new-count" :id="i == 0 ? 'tour-news' : ''">
                                    <loader v-if="feedsStatsBusy" small />
                                    <template v-else-if="f.id in feedsStats">{{ feedsStats[f.id].new_count }}</template>
                                    <template v-else>0</template>
                                </span>
                            </router-button>
                        </template>
                        <div v-else class="empty-menu-item">{{ $t("feeds.menu.empty") }}</div>
                    </div>
                    <div class="mt-auto menu-footer-section">
                        <a
                            href="mailto:kontakt@tendry.cz"
                            class="menu-item menu-item-help"
                        >
                            <icon icon="speaker" />
                            <span>{{ $t("feeds.menu.contact") }}</span>
                        </a>

                        <a href="https://tendry.notion.site/Tipy-a-N-vody-03872dbde7924cb8b565f0783b488e07"
                            class="menu-item menu-item-help"
                            target="_blank"
                        >
                            <icon icon="help"/>
                            <span>{{ $t("feeds.menu.help") }}</span>
                        </a>
                    </div>
                </div>
            </b-col>
            <b-col id="main">
                <b-row v-if="!noHeader" >
                    <b-col id="top-menu" >
                        <b-row no-gutters align-v="center">
                            <b-col class="col-auto col-md-auto order-1 order-md-1 text-left">
                                <button @click.stop="sideMenuExpanded = !sideMenuExpanded"
                                        class="btn-icon menu-toggler">
                                    <icon icon="menu-toggler"/>
                                </button>
                                <button @click="$router.go(-1)" class="btn-icon history prev">
                                    <icon icon="arrow"/>
                                </button>
                                <button @click="$router.go(1)" class="btn-icon history next">
                                    <icon icon="arrow" right/>
                                </button>
                            </b-col>
                            <b-col class="col-12 col-md order-3 order-md-2 title_row">
                                <slot name="title"></slot>
                            </b-col>
                            <b-col class="col col-md-auto order-2 order-md-3 text-right">
                                <slot name="top-menu" :toggleRightPane="toggleRightPane"></slot>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row id="content-wrapper">
                    <b-col id="content">
                        <loader v-show="busy" :label="$t('tender.listLoader')" centered class="content-loader" />
                        <div v-show="!busy">
                            <div id="alerts-container">
                                <SeedPopulateAlert v-if="seedPopulateAlert" class="alert-bubble-row" />

                                <NoCompanyAlert v-if="noCompanyAlert" class="alert-bubble-row" />

                                <slot name="alerts"></slot>
                            </div>

                            <slot :toggleRightPane="toggleRightPane">
                            </slot>
                        </div>
                    </b-col>
                    <b-col cols="4" v-if="rightPaneExpanded" id="right-pane" :class="{expanded: rightPaneExpanded}">
                        <slot name="right-pane" :toggleRightPane="toggleRightPane">
                        </slot>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>

    <TourDiscount :callbacks="getTourCallbacks('discount')" />
    <TourSmart :callbacks="getTourCallbacks('smart')" />
    <TourFeed :callbacks="getTourCallbacks('feed')" />
    <TourSettings :callbacks="getTourCallbacks('settings')" />
    <TourNews :callbacks="getTourCallbacks('news')" />
</div>
