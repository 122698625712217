import Vue from "vue"
import i18n from "@/messages"

function formatNumber(number, digits = 0) {
    if (number === null) {
        return ""
    }
    if (!number) {
        return "0"
    }

    var formatter = new Intl.NumberFormat(i18n.tc("language"), { maximumFractionDigits: digits, minimumFractionDigits: digits });
    return formatter.format(number)
}

Vue.filter("upper", function(v) {
    if (!v) {
        return ""
    }

    return v.toString().toUpperCase()
})

Vue.filter("lower", function(v) {
    if (!v) {
        return ""
    }

    return v.toString().toLowerCase()
})

Vue.filter("integer", function(number) {
    if (!number) {
        return "0"
    }
    var formatter = new Intl.NumberFormat({ maximumFractionDigits: 0, minimumFractionDigits: 0 });
    return formatter.format(number);
})

Vue.filter("number", function(number, digits = 0) {
    return formatNumber(number, digits)
})

Vue.filter("percentage", function(number, digits = 0) {
    if (!number) {
        number = 0
    }

    return formatNumber(number * 100, digits) + "%";
})

Vue.filter("price", function(number, digits = 0) {
    if (!number) {
        number = 0
    }

    return formatNumber(number, digits) + "\xa0Kč";
})

Vue.filter("humanize_price", function(number, digits = 0, long = false) {
    if (!number) {
        number = 0
    }

    if (number === 0) {
        digits = 0
    }

    var fnumber
    if (number > 1) {
        let e = Math.floor(Math.log(number) / Math.log(1000)),
            unit = long ? i18n.tc(`currency.amountsLong.${e}`) : i18n.tc(`currency.amounts.${e}`)

        fnumber = `${(number / Math.pow(1000, e)).toFixed(digits)} ${unit}`
    } else {
        fnumber = number.toFixed(digits)
    }

    return `${fnumber}\xa0${i18n.tc('currency.czk')}`;
})