
<b-row v-if="show" no-gutters>
    <b-col>
        <b-alert show dismissible @dismissed="$emit('dismissed')" class="alert-bubble">
            <template #default>
                <b-row>
                    <b-col>
                        <slot></slot>
                    </b-col>
                    <b-col v-if="$slots.button" cols="12" sm="auto" class="mt-3 mt-sm-0 text-left text-xxxs-right">
                        <slot name="button"></slot>
                    </b-col>
                </b-row>
            </template>

            <template #dismiss>
                <icon icon="x" />
            </template>
        </b-alert>
    </b-col>
</b-row>
