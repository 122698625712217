<template>
    <router-link
        :to="to"
        custom v-slot="{ navigate, isActive, isExactActive }"
    >
        <button
            @click="navigate"
            @keypress.enter="navigate"
            :class="{'router-link-active': (isActive || active) && !deactive, 'router-link-exact-active': isExactActive && !deactive}"
            role="link"
        >
            <slot></slot>
        </button>
    </router-link>
</template>

<script>
export default {
    props: {
        to: {type: Object, required: true},
        active: {type: Boolean, required: false},
        deactive: {type: Boolean, required: false},
    }
}
</script>

<style>

</style>