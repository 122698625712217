
<div class="input password-input">
    <input
        v-model="password"
        name="password"
        :type="hidden ? 'password' : 'text'"
        :placeholder="placeholder ? placeholder : defaultPlaceholder"
        :disabled="disabled"
        @keydown.enter.prevent=""
    >

    <label for="reveal-password">
        <icon :icon="hidden ? 'eye' : 'eye-off'" class="show-password" />
        <input type="checkbox" v-model="hidden" id="reveal-password" />
    </label>
</div>
