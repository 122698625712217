<template>
    <grid-row class="auth-layout">
        <b-row class="justify-content-center">
            <b-col class="logo">
                 <router-link :to="{name: 'home'}">
                    <icon icon="datlab" />
                    <div>{{ $t('tendry') | upper }}</div>
                </router-link>
            </b-col>
        </b-row>

        <slot name="title">
            <h1>{{ title }}</h1>
        </slot>

        <b-row class="justify-content-center">
            <b-col class="content">
                <div v-for="(e, i) in errorsArr" :key="`err-${i}`" class="error">{{ e }}</div>

                <slot></slot>
            </b-col>
        </b-row>
    </grid-row>
</template>

<script>
export default {
    props: {
        title: { type: String },
        error: { type: [String, Array] }
    },
    computed: {
        nav: function () {
            return {
            }
        },
        errorsArr: function() {
            if (!this.error) {
                return []
            }

            return Array.isArray(this.error) ? this.error : [this.error]
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/custom";
@import "src/scss/utils";

.auth-layout {
    padding-top: 64px;
    padding-bottom: 64px;

    .logo {
        font-size: 35px;
        color: $color-red;
        text-align: center;
        margin-bottom: 56px;
        font-weight: 800;
        letter-spacing: 0.94px;

        .icon {
            color: $body-color;
            font-size: 27px;
        }

        a{
            display: inline-block;

            &:hover {
                color: $color-red;
                text-decoration: none;
            }
        }
    }

    h1 {
        font-size: 30px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 48px;
    }

    input {
        border-radius: 14px;
        padding: 20px 24px;
        font-size: 18px;
        background-color: $background-light;
        border: none;
        display: block;
        width: 100%;

        &:focus-visible {
            outline: none;
        }
    }

    button {
        border-radius: 14px;
        color: $body-bg;
        padding: 20px 24px;
        border: none;
        display: block;
        width: 100%;
        font-weight: 700;
        background-color: $color-red;

        &:not([disabled]) {
            @include colorize-clickable($color-red);
        }

        &[disabled] {
            opacity: 0.6;
        }

        .icon {
            font-size: 24px
        }
    }

    small {
        display: block;
        margin-top: 15px;
        font-size: 14px;
        text-align: center;

        a {
            font-weight: 600;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
            }
        }
    }

    .light {
        opacity: 0.5;
    }

    .error {
        color: $color-red;
        font-size: 12px;
        font-style: italic;
        margin-bottom: 12px;
        opacity: 0.6;
    }

    .content {
        max-width: 400px;
        text-align: center;

        form {
            & > input + input,
            & > input + button,
            & > .input + .input,
            & > input + .input,
            & > .input + input,
            & > .input + button {
                margin-top: 16px;
            }
        }
    }

    .success {
        font-size: 18px;
        font-weight: 700;
        color: $success;
        text-align: center;

        .icon {
            margin-right: 15px;
        }

        a {
            color: $body-color;
            opacity: 0.5;
        }
    }
}
</style>