
<Tour name="discount" :steps="steps" :options="options" :callbacks="callbacks" id="discount-tour">
    <template #content>
        <div class="description">
            <p v-html="$t('tours.discount.p1')"></p>
            <p v-html="$t('tours.discount.p2')"></p>
        </div>
    </template>

    <template #actions="{tour}">
        <div class="row">
            <div class="col col-12 d-none d-md-block col-md-6">
                <p class="note" v-html="$t('tours.discount.try')"></p>
                <button @click="tour.finish" class="v-step__button">{{ $t('tours.discount.btnTry') }}</button>
            </div>
            <div class="col col-12 col-md-6">
                <p class="note" v-html="$t('tours.discount.buy')"></p>
                <button @click="buy" class="v-step__button button-buy">{{ $t('tours.discount.btnBuy') }}</button>
                <button @click="tour.finish" class="v-step__button d-md-none mt-3">{{ $t('tours.discount.btnTry') }}</button>
            </div>
        </div>
    </template>
</Tour>
