<template>
    <div>
        <AlertBubble
            :show="showSeedPopulationBubble && !isAlertDismissed"
            @dismissed="dismissAlert"
        >
            {{ $t("feed.populate.text") }}

            <template #button>
                <button class="btn btn-light update" @click="$bvModal.show('populate-seed-modal')">
                    {{ $t("feed.populate.btn") }}
                </button>
            </template>
        </AlertBubble>

        <ConfirmModal
            id="populate-seed-modal"

            @ok="populateSeed"

            :title="$t('settings.populateSeed.confirm.title')"
            :text="$t('settings.populateSeed.confirm.body')"
            :ok-btn-label="$t('settings.populateSeed.confirm.ok')"
            :cancel-btn-label="$t('settings.populateSeed.confirm.cancel')"
            ok-btn-variant="dark"
        />
    </div>
</template>

<script>
import AlertBubble from "@/components/AlertBubble.vue"
import ConfirmModal from "@/components/ConfirmModal.vue"

export default {
    data: function() {
        return {
            seedSize: 0,
            busy: false
        }
    },
    components: {
        AlertBubble,
        ConfirmModal
    },
    created: function() {
        this.$store.commit("setNewLikesCount", 0)

        this.busy = true

        Promise.all([
            this.$store.dispatch("updateNewLikesCount"),
            this.updateSeedSize()
        ]).finally(() => this.busy = false)
    },
    computed: {
        isSeedPopulationEnabled: function() {
            return this.$store.getters.isSeedPopulationEnabled
        },
        newLikesCount: function() {
            return this.$store.getters.newLikesCount
        },
        showSeedPopulationBubble: function() {
            return !this.busy && this.isSeedPopulationEnabled && this.newLikesCount * 10 >= this.seedSize
        },
        isAlertDismissed: function() {
            return this.$store.getters.populationAlertDismissed
        }
    },
    methods: {
        updateSeedSize: async function() {
            var resp = await this.$store.getters.api.get("seed-size/")
            this.seedSize = resp.data
        },
        populateSeed: function() {
            this.$store.dispatch("populateSmartFeedSeed")
            this.$router.push({name: "feed-complete"})
        },
        dismissAlert: function() {
            this.$store.dispatch("dismissPopulationAlert")
        }
    }
}
</script>

<style>

</style>