<template>
    <router-view />
</template>

<script>
export default {
    created: function() {
        document.title = this.$t("title")

        this.$root.$on("set-page-title", title => document.title = title)
    }
}
</script>

<style lang="scss">
@import "src/scss/main";
</style>
