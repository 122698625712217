<template>
    <div class="input password-input">
        <input
            v-model="password"
            name="password"
            :type="hidden ? 'password' : 'text'"
            :placeholder="placeholder ? placeholder : defaultPlaceholder"
            :disabled="disabled"
            @keydown.enter.prevent=""
        >

        <label for="reveal-password">
            <icon :icon="hidden ? 'eye' : 'eye-off'" class="show-password" />
            <input type="checkbox" v-model="hidden" id="reveal-password" />
        </label>
    </div>
</template>

<script>
export default {
    props: {
        value: {},
        placeholder: {type: String},
        disabled: {type: Boolean, default: false}
    },
    data: function() {
        return {
            hidden: true
        }
    },
    computed: {
        defaultPlaceholder: function() {
            return this.$t('auth.password')
        },
        password: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "src/scss/custom";

.password-input {
    position: relative;
    display: block;
    width: 100%;

    input {
        padding-right: 48px;
    }

    label[for=reveal-password] {
        position: absolute;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        opacity: 0.6;
        color: $body-color;
        width: auto;
        right: 20px;
        top: 20px;
        cursor: pointer;

        .icon {
            font-size: 20px;
        }

        &:hover {
            background: none;
            border: none;
            opacity: 1;
        }

        input {
            display: none
        }
    }
}
</style>