<template>
    <Tour name="discount" :steps="steps" :options="options" :callbacks="callbacks" id="discount-tour">
        <template #content>
            <div class="description">
                <p v-html="$t('tours.discount.p1')"></p>
                <p v-html="$t('tours.discount.p2')"></p>
            </div>
        </template>

        <template #actions="{tour}">
            <div class="row">
                <div class="col col-12 d-none d-md-block col-md-6">
                    <p class="note" v-html="$t('tours.discount.try')"></p>
                    <button @click="tour.finish" class="v-step__button">{{ $t('tours.discount.btnTry') }}</button>
                </div>
                <div class="col col-12 col-md-6">
                    <p class="note" v-html="$t('tours.discount.buy')"></p>
                    <button @click="buy" class="v-step__button button-buy">{{ $t('tours.discount.btnBuy') }}</button>
                    <button @click="tour.finish" class="v-step__button d-md-none mt-3">{{ $t('tours.discount.btnTry') }}</button>
                </div>
            </div>
        </template>
    </Tour>
</template>

<script>
import Tour from "@/components/tours/Tour.vue"

export default {
    props: {
        callbacks: {type: Object, required: false},
        options: {type: Object, required: false}
    },
    components: {
        Tour
    },
    computed: {
        steps: function () {
            return  [{
                target: 'body',
                header: {
                    title: this.$t('tours.discount.title')
                },
                params: {
                    modifiers: [
                        // resolve fixed position, places tour in the middle of the screen
                        {
                            name: 'computeStyles',
                            enabled: true,
                            fn({state}) {
                                state.styles.popper = {
                                    ...state.styles.popper,
                                    position: 'fixed',
                                    left: `${(window.innerWidth - state.rects.popper.width) / 2}px`,
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                }

                                return state
                            }
                        },
                    ]
                }
            }]
        }
    },
    methods: {
        buy: function() {
            this.$router.push({ path: '/subscription' });
            this.$tours["discount"].skip();
        }
    }
}
</script>

<style lang="scss">
@import "src/scss/custom";

#discount-tour {
    .v-step {
        max-width: 550px;
        text-align: left;
        padding: 25px;
    }

    .v-step__button {
        margin: 0;
    }

    .v-step__header {
        background-color: $body-bg;
        color: $body-color;
        font-size: 28px;
        font-weight: 700;
        padding: 0;
        margin: 0;
        margin-bottom: 20px;
    }

    .description {
        line-height: 140%;
    }

    .actions {
        line-height: 140%;

        margin-top: 30px;

        button {
            width: 100%;
        }

        .button-buy {
            background-color: $body-color;
            color: $body-bg;
        }

        .note {
            height: 120px;
        }
    }
}
</style>