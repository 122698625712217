<template>
    <div class="loader" :class="{'justify-content-center': centered, 'align-items-center': centered || inline, inline: inline}">
        <b-spinner :small="small" :label="label"></b-spinner>
        <div class="label" v-if="label">{{ label }}</div>
    </div>
</template>

<script>
export default {
    props: {
        centered: {type: Boolean, default: false},
        label: {type: String},
        small: {type: Boolean, default: false},
        inline: {type: Boolean, default: false}
    }
}
</script>

<style lang="scss">
@import "src/scss/custom";

.loader {
    width: 100%;
    display: flex;
    flex-direction: column;

    &.inline {
        display: inline-flex;
        width: auto;
        vertical-align: middle;
    }

    .label {
        margin-top: 10px;
    }

    &.inline {
        flex-direction: row;
        & > * {
            margin-top: 0;
        }

        .label {
            margin-left: 10px;
        }
    }
}
</style>