import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import {CONFIG} from '@/config.js';

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        beforeEnter() {
            window.location.replace(CONFIG.landingPageUrl);
        }
    },
    {
        path: '/feeds',
        name: 'feeds',
        meta: { requiresAuth: true },
        component: () => import('@/views/Feeds.vue')
    },
    {
        path: '/search',
        name: 'search',
        meta: { requiresAuth: true },
        component: () => import('@/views/Search.vue')
    },
    {
        path: '/feed/create',
        name: 'feed-create',
        meta: { requiresAuth: true },
        component: () => import('@/views/FeedCreate.vue')
    },
    {
        path: '/feed/complete',
        name: 'feed-complete',
        component: () => import('@/views/FeedComplete.vue')
    },
    {
        path: '/feed/:id',
        name: 'feed-detail',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('@/views/FeedDetail.vue')
    },
    {
        path: '/feed/:feedId/tender/:tenderId',
        name: 'feed-tender-detail',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('@/views/FeedTenderDetail.vue')
    },
    {
        path: '/tender/:tenderId',
        name: 'tender-detail',
        props: route => {
            return {
                tenderId: route.params.tenderId,
                noFeed: true
            }
        },
        meta: { requiresAuth: true },
        component: () => import('@/views/FeedTenderDetail.vue')
    },
    {
        path: '/configurator',
        name: 'feed-configurator',
        meta: { requiresAuth: true },
        component: () => import('@/views/FeedConfigurator.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/Login.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/views/Logout.vue')
    },
    {
        path: '/signup',
        name: 'signup',
        component: () => import('@/views/Signup.vue')
    },
    {
        path: '/activate/:activationKey',
        name: 'user-activation',
        props: true,
        component: () => import('@/views/UserActivation.vue')
    },
    {
        path: '/reset-password',
        name: 'reset-password-request',
        component: () => import('@/views/ResetPasswordRequest.vue')
    },
    {
        path: '/reset-password/:activationKey',
        name: 'reset-password',
        props: true,
        component: () => import('@/views/ResetPassword.vue')
    },
    {
        path: '/terms',
        name: 'terms',
        props: true,
        component: () => import('@/views/Terms.vue')
    }
    ,
    {
        path: '/privacy-agreement',
        name: 'privacy-agreement',
        props: true,
        component: () => import('@/views/PrivacyAgreement.vue')
    },
    {
        path: '/news',
        name: 'news',
        meta: { requiresAuth: true },
        component: () => import('@/views/News.vue')
    },
    {
        path: '/news/:tenderId',
        name: 'news-detail',
        props: route => {
            return {
                tenderId: route.params.tenderId,
                noFeed: true,
                news: true
            }
        },
        meta: { requiresAuth: true },
        component: () => import('@/views/FeedTenderDetail.vue')
    },
    {
        path: '/settings',
        name: 'settings',
        meta: { requiresAuth: true },
        component: () => import('@/views/Settings.vue')
    },
    {
        path: '/subscription',
        name: 'subscription',
        meta: { requiresAuth: true },
        component: () => import('@/views/Subscription.vue')
    },
    {
        path: '/subscription/result/:result/',
        name: 'subscription-result',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('@/views/Subscription.vue')
    },
    {
        path: '/subscription/result/:result/session_id/:stripe_session_id',
        name: 'subscription-result-session',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('@/views/Subscription.vue')
    },
    {
        path: '/feed/:id/dashboard',
        name: 'dashboard',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('@/views/SmartFeedDashboard.vue')
    },
    {
        path: '/dashboard/:organizationId',
        name: 'smart-feed-dashboard',
        props: true,
        meta: { requiresAuth: true },
        component: () => import('@/views/SmartFeedDashboard.vue')
    },
    {
        path: '/applog',
        name: 'applog',
        props: true,
        meta: { requiresAuth: true },
        component: () => import(/* webpackMode: "lazy", webpackChunkName: "tendryapp" */ '@/views/Applog.vue')
    },
    {
        path: '/applog/user/:id',
        name: 'applog-user-detail',
        props: true,
        meta: { requiresAuth: true },
        component: () => import(/* webpackMode: "lazy", webpackChunkName: "tendryapp" */ '@/views/ApplogUserDetail.vue')
    },
    {
        path: '/permission-denied',
        name: 'permission-denied',
        component: () => import('@/views/PermissionDenied.vue')
    },
    {
        path: '*',
        name: 'page-not-found',
        component: () => import('@/views/PageNotFound.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: import.meta.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        var pos = { x: 0, y: 0 }
        if (savedPosition) {
            pos = savedPosition
        }

        store.commit("setScrollPosition", pos)

        return pos
    }
})


router.beforeEach((to, from, next) => {
    // utm_source is required
    if (to.query && "utm_source" in to.query) {
        // collect utm params
        let utmParams = {}
        Object.entries(to.query).forEach(([key, value]) => {
            if (key.startsWith("utm_") || key === "dtlbclid") {
                utmParams[key] = value
            }
        })

        // forward them to the django backend
        store.getters.api.get("utm/", {params: utmParams})
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
        store.dispatch("loadUser")
            .finally(() => {
                if (!store.getters.loggedIn) {
                    next({name: "login", query: {next: to.path}})
                } else {
                    next()
                }
            })
    } else if(to.matched.some(record => record.name === "home")){
        if(store.getters.loggedIn && store.getters.smartFeed) {
            next({ path: `/feed/${store.getters.smartFeed.id}`})
        } else {
            next()
        }
    }
    else {
        next()
    }
})

export default router
