<template>
    <AlertBubble
        :show="!busy && company == null && !isAlertDismissed"
        @dismissed="dismissAlert"
    >
        {{ $t("noCompanyAlert.text") }}

        <template #button>
            <button class="btn btn-light" @click="$router.push({'name': 'settings'})">
                {{ $t("noCompanyAlert.btn") }}
            </button>
        </template>
    </AlertBubble>
</template>

<script>
import AlertBubble from "@/components/AlertBubble.vue"

export default {
    data: function() {
        return {
            company: null,
            busy: false
        }
    },
    components: {
        AlertBubble
    },
    created: function() {
        this.busy = true
        this.updateCompany().then(this.busy = false)
    },
    computed: {
        isAlertDismissed: function() {
            return this.$store.getters.noCompanyAlertDismissed
        }
    },
    methods: {
        dismissAlert: function() {
            this.$store.dispatch("dismissNoCompanyAlert")
        },
        updateCompany: async function() {
            var resp = await this.$store.getters.api.get("smart-feed-company/")
            this.company = resp.data
        }
    }
}
</script>

<style>

</style>